import { images } from '../../assets'
import { Header } from '../../components'
import './About.scss'

const About = () => {
    return (
        <div className="about" id='about'>

            <Header title='about' />

                <div className='wrapper' >
                    <img src={images.tori4} alt='' />
                    <p>Victoria "Tori" Carpenter is a self taught artist with over 20 years experience
                        painting subject matter ranging from ocean scenes to psychedelic mushrooms. She loves
                        to explore what she is capable of with acrylic paints, mostly. Her goals include
                        experimenting with oil paints and mixed media art. She currently resides in Northern
                        Virginia working as Director of Art and Marketing as an artist for Pinot's Palette-Dulles.
                        When she's not painting, Tori enjoys rock climbing and roller skating. Tori is studying
                        American Sign Language for Interpreting and hopes to merge her two loves by hosting a
                        paint class for Deaf individuals in the future.
                    </p>
                    <img src={images.tori11} alt='' />
                </div>
        </div>
    )
}

export default About
