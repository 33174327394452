import { useRef } from 'react'
import { useInView } from "framer-motion";
import './Header.scss';

const Header = ({ title }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false })
    return (
        <div
            className='header'
            id='title'
            ref={ref}
            style={{
                transform: isInView ? "none" : "translateX(-100px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
            }}>
            <h1>{title}</h1>
        </div>
    )
}

export default Header