import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import './Contact.scss'
import { Header } from '../../components';

const SERVICE_ID = 'service_n3ajqs9'
const USER_ID = '8PSCxwab4y0Af4mls'
const TEMPLATE_ID = 'template_0hwar6w'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((res) => {
        console.log(res.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully, Thanks!'
        })
      }, (error) => {
        console.log(error.text)
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      })
    e.target.reset()
  }

  return (

    <div className='contact' id='contact'>
      <Header title='CONTACT' />
      <div className='wrapper'>
        <div className='left'>
          <div className='info'>
            <h2>Questions or Enquiries?</h2>
            <p>Thank you for your interest in my art!</p>
            <p>If you have any questions or would like to know more, feel free to contact me! </p>
          </div>
        </div>
        <div className='right'>
          <form ref={form} onSubmit={sendEmail} className='form'>
            <input
              className='input'
              type='text'
              name='user_name'
              placeholder='Name' />
            <input
              className='input'
              type='text'
              name='user_email'
              placeholder='Email' />
            <textarea
              className='message'
              name='message'
              placeholder='Message' />
            <button
              className='form-btn'
              type='submit'
              value='send'>
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>

  )
}

export default Contact