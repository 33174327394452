import { Header } from '../../components'
import { images } from '../../assets';
import './Gallery.scss'


const Gallery = () => {

    return (
        <>
            <div className='gallery' id='#gallery'>
                <Header title='GALLERY' />
                <div className='wrapper'>
                    <div className='col'>
                        <div className='img-wrapper'>
                            <img className='img' src={images.experiment} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'>"Experiment"</p>
                                    <p className='year'>2023</p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>24"x 24"</p>
                                    <p className='price'>$300</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.oilSpill2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'></p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.BWFlower2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'></p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.texturedGoldBlue2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    {/* <p className='forsale'>FOR SALE!</p> */}
                                    <p className='title fz-18 fz-4'>"Try Not to Touch, Okay Maybe a Little"</p>
                                    <p className='year fz-4'>2023</p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc fz-4'>High Gloss Gel with Acrylic on Canvas</p>
                                    <p className='size fz-4'>Framed 10" x 30"</p>
                                    <p className='price fz-18 fz-4 pt-2'>$200</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.dogColorful2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>"Watercolor Buddy"</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Print</p>
                                    <p className='size'>11" x 14"</p>
                                    <p className='price'>$30</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.flowers1} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>"Bursting at the Blooms"</p>
                                    <p className='year'>2021</p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>High Gloss Gel with Acrylic on Canvas</p>
                                    <p className='size'>16" x 20" x 1.5"</p>
                                    <p className='price'>$150</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.dogSmallBrown2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>Pet Portrait Commissions</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>11" x 14" | $150</p>
                                    <p className='size'>20" x 16" | $250</p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='img-wrapper'>
                            <img className='img' src={images.oceanSunset2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'></p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.texturedBlueGray1} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    {/* <p className='forsale'>FOR SALE!</p> */}
                                    <p className='title'>"Into the Rain"</p>
                                    <p className='year'>2020</p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>24" x 24"</p>
                                    <p className='price'>$80</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.dogShepherd2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>Pet Portrait Commissions</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>11" x 14" | $150</p>
                                    <p className='size'>20" x 16" | $250</p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.dogPug2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>Pet Portrait Commissions</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>11" x 14" | $150</p>
                                    <p className='size'>20" x 16" | $250</p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.familyPortrait} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'></p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.dogBernese2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>Pet Portrait Commissions</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>11" x 14" | $150</p>
                                    <p className='size'>20" x 16" | $250</p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='img-wrapper'>
                            <img className='img' src={images.eye1} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    {/* <p className='forsale'>FOR SALE!</p> */}
                                    <p className='title'>"Point of View"</p>
                                    <p className='year'>2023</p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>16" x 20" | $230</p>
                                    <p className='desc pt-10'>Print</p>
                                    <p className='size'>11" x 14" | $30</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.moodyBeach} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'>"Moody Beach"</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Print</p>
                                    <p className='size'>11" x 14"</p>
                                    <p className='price'>$30</p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.landscape2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'></p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.dogSmallWhite2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>Pet Portrait Commissions</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>11" x 14" | $150</p>
                                    <p className='size'>20" x 16" | $250</p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.gleamingSea2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'>"Gleaming Sea"</p>
                                    <p className='year'>2023</p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.cat2} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='title'>Pet Portrait Commissions</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'>Acrylic on Canvas</p>
                                    <p className='size'>11" x 14" | $150</p>
                                    <p className='size'>20" x 16" | $250</p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img className='img' src={images.garageMural3} alt='' />
                            <div className='img-info'>
                                <div className='top'>
                                    <p className='sold'>SOLD!</p>
                                    <p className='title'>"Garage Mural"</p>
                                    <p className='year'></p>
                                </div>
                                <div className='bottom'>
                                    <p className='desc'></p>
                                    <p className='size'></p>
                                    <p className='price'></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Gallery