// import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { Gallery, About, Shop, Contact } from './pages';
import { Navbar, Footer } from './components'
import './App.scss';

// const Layout = () => {
//   return (
//     <div className="app">
//       <Navbar />
//       <Outlet />
//       <Footer />
//     </div>
//   )
// }


// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <Layout />,
//     children: [
//       {
//         path: '/',
//         element: <Gallery />
//       },
//       {
//         path: '/about',
//         element: <About />
//       },
//       {
//         path: '/shop',
//         element: <Shop />
//       },
//       {
//         path: '/contact',
//         element: <Contact />
//       },
//     ]
//   }
// ])


function App() {
  return (
    <div className='app'>
      {/* <RouterProvider router={router} /> */}
      <Navbar />
      <Gallery />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
