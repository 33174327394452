import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-modal';
import './Navbar.scss'

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function toggleOverlay() {
    setToggleMenu(false)
  }

  return (
    <div className="navbar">

      <div className='wrapper'>
        <div className='left'>
          <div className='logo'>
            <a className='link' href='/gallery'>
              <h1>Victoria <br /> <span>Carpenter</span></h1>
            </a>
          </div>
        </div>

        <div className='right'>
          <div className='item'>
            <a className='link' href='/gallery'>Gallery</a>
          </div>
          <div className='item'>
            <a className='link' href='#about'>About</a>
          </div>
          <div className='item'>
            <a className='link' href='#shop' onClick={toggleModal}>Shop</a>
            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              ariaHideApp={false}
              contentLabel="Coming Soon!"
              style={{
                content: {
                  backgroundImage: 'linear-gradient(30deg, #008080, #808080, #856b89)',
                  opacity: '80%',
                  borderRadius: '2rem',
                  marginTop: '6rem',
                }
              }}
            >
            <div className='modal-info-wrapper'>
              <h1>Coming Soon!</h1>
              <button className='modal-close-button' onClick={toggleModal}>Close</button>
            </div>
            </Modal>
          </div>
          <div className='item'>
            <a className='link' href='#contact'>Contact</a>
          </div>
        </div>

        <div className='smallScreen'>
          <MenuIcon className='open' onClick={() => setToggleMenu(true)} />
          {toggleMenu && (
            <div className='overlay slide-bottom'>
              <CloseIcon className='close' onClick={() => setToggleMenu(false)} />
              <div className='item'>
                <a className='link' href='/gallery' onClick={() => setToggleMenu(false)}>Gallery</a>
              </div>
              <div className='item'>
                <a className='link' href='#about' onClick={() => setToggleMenu(false)}>About</a>
              </div>
              <div className='item'>
                <a className='link' href='#shop' onClick={e => {toggleOverlay(); toggleModal()}}>Shop</a>
              </div>
              <div className='item'>
                <a className='link' href='#contact' onClick={() => setToggleMenu(false)}>Contact</a>
              </div>
            </div>
          )}
        </div>

      </div>

    </div>
  )
}

export default Navbar