import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import './Footer.scss'

const currentDate = new Date()

const Footer = () => {
  return (
    <div className="footer">
      <div className='wrapper'>

        <p className='social-info'>Keep up to date with my new art and events by following me!</p>
        <div className='social'>
          <div className='links'>
            <div className='icon'>
              <a
                href="https://www.instagram.com/artist.tori/"
                target="_blank"
                rel="noreferrer noopener"
              ><InstagramIcon fontSize='4rem' style={{ color: '#856B89' }} /></a>
            </div>
            <div className='icon'>
              <a
                href="https://www.facebook.com/toriflakes"
                target="_blank"
                rel="noreferrer noopener"
              ><FacebookIcon fontSize='4rem' style={{ color: '#856B89' }} /></a>
            </div>
          </div>
        </div>

        <div className='copyright'>
          <p className='title'>VICTORIA CARPENTER</p>
          <span> © {currentDate.getFullYear()}</span>
        </div>

      </div>
    </div>
  )
}

export default Footer