import BWFlower from './b&w-flower.jpg';
import BWFlower2 from './b&w-flower2.jpg';
import cat from './cat.jpg';
import cat2 from './cat2.jpg';
import dogBernese2 from './dog-bernese2.jpg';
import dogBernese from './dog-bernese.jpg';
import dogColorful2 from './dog-colorful2.jpg';
import dogPug from './dog-pug.jpg';
import dogPug2 from './dog-pug2.jpg';
import dogShepherd from './dog-shepherd.jpg';
import dogShepherd2 from './dog-shepherd2.jpg';
import dogSmallBrown from './dog-small-brown.jpg';
import dogSmallBrown2 from './dog-small-brown2.jpg';
import dogSmallWhite from './dog-small-white.jpg';
import dogSmallWhite2 from './dog-small-white2.jpg';
import experiment from './experiment.jpg';
import eye1 from './eye-1.jpg';
import eye2 from './eye-2.jpg';
import familyPortrait from './family-portrait.jpg';
import flowers1 from './flowers-1.jpg';
import flowers2 from './flowers-2.jpg';
import garageMural1 from './garage-mural-1.jpg';
import garageMural2 from './garage-mural-2.jpg';
import garageMural3 from './garage-mural-3.jpg';
import gleamingSea from './gleaming-sea.jpg';
import gleamingSea2 from './gleaming-sea2.jpg';
import landscape from './landscape.jpg';
import landscape2 from './landscape2.jpg';
import moodyBeach from './moodyBeach.jpg'
import oceanSunset from './ocean-sunset.jpg';
import oceanSunset2 from './ocean-sunset2.jpg';
import ocean from './ocean.jpg';
import ocean2 from './ocean2.jpg';
import oilSpill from './oil-spill.jpg';
import oilSpill2 from './oil-spill2.jpg';
import texturedBlueGray1 from './textured-blue-gray-1.jpg';
import texturedBlueGray2 from './textured-blue-gray-2.jpg';
import texturedGoldBlue from './textured-gold-blue.jpg';
import texturedGoldBlue2 from './textured-gold-blue-2.png';
import texturedGoldBlue3 from './textured-gold-blue-3.jpg';
import tori1 from './tori-1.jpg';
import tori2 from './tori-2.jpg';
import tori3 from './tori-3.jpg';
import tori4 from './tori-4.jpg';
import tori11 from './tori-11.jpg';


export default {
    BWFlower,
    BWFlower2,
    cat,
    cat2,
    dogBernese,
    dogBernese2,
    dogColorful2,
    dogPug,
    dogPug2,
    dogShepherd,
    dogShepherd2,
    dogSmallBrown,
    dogSmallBrown2,
    dogSmallWhite,
    dogSmallWhite2,
    experiment,
    eye1,
    eye2,
    familyPortrait,
    flowers1,
    flowers2,
    garageMural1,
    garageMural2,
    garageMural3,
    gleamingSea,
    gleamingSea2,
    landscape,
    landscape2,
    moodyBeach,
    oceanSunset,
    oceanSunset2,
    ocean,
    ocean2,
    oilSpill,
    oilSpill2,
    texturedBlueGray1,
    texturedBlueGray2,
    texturedGoldBlue,
    texturedGoldBlue2,
    texturedGoldBlue3,
    tori1,
    tori2,
    tori3,
    tori4,
    tori11
}